import {
  START_COUNTDOWN,
  UPDATE_TIME,
  RESET_TIME,
} from "../ActionTypes/ActionTypes";

const initState = {
  startTime: null,
  initialRemainingTime: 60,
  remainingTime: 60,
};

export const TimeReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case START_COUNTDOWN:
      return {
        ...state,
        startTime: payload,
        initialRemainingTime: state.remainingTime,
      };

    case UPDATE_TIME:
      return {
        ...state,
        remainingTime: payload,
      };
    case RESET_TIME:
      return initState;
    default:
      return state;
  }
};
