import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LeaderBoard from "./components/LeaderBoard";
import AppWrapper from "./App";
import StartPhone from "./components/StartPhone";
import StartName from "./components/StartName";
import StartQuestions from "./components/StartQuestions";
import Score from "./components/Score";
import VerifyOtp from "./components/VerifyOtp";
function AppRoutes() {
  return (
    <Router>
      <AppWrapper>
        <Routes>
          <Route path="/" element={<LeaderBoard />} />
          {/* <Route path="/start/phone" element={<StartPhone />} />
          <Route path="/start/name" element={<StartName />} />
          <Route path="/start/questions" element={<StartQuestions />} />
          <Route path="/score" element={<Score />} />
          <Route path="/verify/otp" element={<VerifyOtp />} /> */}
        </Routes>
      </AppWrapper>
    </Router>
  );
}
export default AppRoutes;
