import {
  USER_TOKEN,
  QUESTION_OPTIONS,
  QUIZ_MARKS,
} from "../ActionTypes/ActionTypes";

const initState = {
  user: null,
  answerOptions: [],
  quizMarks: {},
};

export const CommonReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case USER_TOKEN:
      return { ...state, user: payload };
    case QUESTION_OPTIONS:
      return { ...state, answerOptions: payload };
    case QUIZ_MARKS:
      return { ...state, quizMarks: payload };
    default:
      return state;
  }
};
