import "./styles.scss";
import FooterImg from "./assets/img/Euro-Football-logo.svg";
import HeaderImg from "./assets/img/Original-Logo.svg";
import { useLocation } from 'react-router-dom';
import HeaderSmallImg from "./assets/img/Original-Logo-small.svg";
import FootballSmallImg from "./assets/img/Football-logo-small.svg";

export default function AppWrapper({ children }) {
  let location = useLocation();
  const isQuestionsScreen = location?.pathname === '/start/questions';
  return (
    <div className="App">
      <div className="app-content">
        <div className="content-wrapper">
          <div className={`header ${isQuestionsScreen ? 'header-small' : ''}`}>
            {isQuestionsScreen ? <>
              <img src={HeaderSmallImg} />
              <img src={FootballSmallImg} />
            </>
              :
              <img src={HeaderImg} />
            }
          </div>
          <div className="content-box">
            {children}
          </div>
        </div>
        <div className="footer">
          <img src={FooterImg} />
        </div>
      </div>
    </div>
  );
}
