import React, { useEffect, useState } from "react";
import SoccerBall from "../assets/img/Soccer_Ball.svg";
import GoalLogo from "../assets/img/Football-logo.svg";
import FlyBall from "../assets/img/soccer_ball_fly.png";
import LeaderBoardLogo from "../assets/img/Leaderboard.svg";
import { Link } from "react-router-dom";
import AxiosInstance from "../Redux/Axios/Axios";
import { useDispatch } from "react-redux";
import { Spin } from 'antd';
import {
  StoreAnswers,
  StoreMarks,
  SaveUserData
} from "../Redux/Actions/Action";

function LeaderBoard() {
  const dispatch = useDispatch();
  
  const [leadboard, setleadboard] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    
    fetchLeadBoard();
    dispatch((dispatch) => {
      dispatch({ type: "RESET_TIME" });
    });
    dispatch(SaveUserData(null));
    dispatch(StoreMarks([]));
    dispatch(StoreAnswers([]));
    localStorage.removeItem("persist:root");
  }, []);

  const fetchLeadBoard = () => {
    AxiosInstance.get(`/api/highest-score`)
      .then((res) => {
        console.log(res);
        const Data = res?.data;
        console.log(Data?.top_scores);
        setleadboard(Data?.top_scores);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  return (
    <>
   <Spin spinning={isLoading}>
    <div className="content">
      <div className="goallogo">
        <img src={GoalLogo} className="goallogoimg" />
      </div>
      <p style={{fontSize:20, textAlign:"center"}}>
      Thank you for supporting our campaign! Your participation and contributions have made a significant impact. <br/>Stay tuned for updates 
      </p>
      {/* <div className="leaderboardlogo">
        <img src={LeaderBoardLogo} className="goallogoimg" />
      </div> */}
      {/* <ul className="leaderboard-list">
        {leadboard?.map((item, index) => {
          return (
            <li key={index}>
              <p title={item.name}>
                {index + 1}. {item.name}
              </p>
              <div className="score">
                <img src={SoccerBall} className="goallogoimg" />
                <p>{item.total_marks}</p>
              </div>
            </li>
          );
        })}
      </ul> */}
      {/* <Link to={`/start/phone`}>
        <button className="btn btn-full btn-secondary">START</button>
      </Link> */}
    </div>
    </Spin>
    </>
  );
}

export default LeaderBoard;
