import { configureStore } from "@reduxjs/toolkit";
import { CommonReducer } from "../Redux/Reducers/Reducer";
import { TimeReducer } from "../Redux/Reducers/TimeReducer";
import { thunk } from "redux-thunk";
import { persistReducer, persistStore } from "redux-persist";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import hardSet from "redux-persist/es/stateReconciler/hardSet";
// import autoMergeLevel1 from "redux-persist/es/stateReconciler/autoMergeLevel1";
const rootReducer = combineReducers({
  common: CommonReducer,
  timer: TimeReducer,
});

const persistConfig = {
  key: "root",
  storage: storage,
  stateReconciler: hardSet,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Middleware callback function
const middleware = (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ["persist/PERSIST"],
      ignoredPaths: ["register"],
    },
  }).concat(thunk);
export const store = configureStore({
  reducer: persistedReducer,
  middleware,
  // devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);
