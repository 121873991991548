import axios from "axios";
import { store } from "../../Store/Store";

const BASEURL = "https://zone-ch.shopezy.app";

const AxiosInstance = axios.create({
  baseURL: BASEURL,
});

AxiosInstance.interceptors.request.use((config) => {
  const auth = store.getState().common?.user;

  if (auth && auth.token) {
    config.headers = {
      Authorization: `Bearer ${auth.token}`,
      Accept: "application/json",
    };
  } else {
    config.headers = {
      Accept: "application/json",
    };
  }
  return config;
});

export default AxiosInstance;
