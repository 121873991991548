import {
  USER_TOKEN,
  START_COUNTDOWN,
  UPDATE_TIME,
  QUESTION_OPTIONS,
  QUIZ_MARKS,
} from "../ActionTypes/ActionTypes";

export function SaveUserData(data) {
  return {
    type: USER_TOKEN,
    payload: data,
  };
}
export function StoreAnswers(data) {
  return {
    type: QUESTION_OPTIONS,
    payload: data,
  };
}
export function StoreMarks(data) {
  return {
    type: QUIZ_MARKS,
    payload: data,
  };
}
export const startCountdown = () => ({
  type: START_COUNTDOWN,
  payload: Date.now(),
});

export const updateTime = (remainingTime) => ({
  type: UPDATE_TIME,
  payload: remainingTime,
});
